import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Text Fields allow users to enter and edit text content into a UI. They typically appear in forms.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-1.svg",
      "alt": "anatomy guideline textfield"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Container :`}</strong>{` selection control (input) that can be selected or unselected, and pushed into different states.`}</li>
      <li parentName="ol"><strong parentName="li">{`Label :`}</strong>{` indicates what you want the user to input in the text field.`}</li>
      <li parentName="ol"><strong parentName="li">{`Input Container :`}</strong>{` interactive input area.`}</li>
      <li parentName="ol"><strong parentName="li">{`Icon :`}</strong>{` Used to add a component to the start or the end of the input container.`}</li>
    </ol>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-2.svg",
      "alt": "anatomy guideline textfield"
    }}></img>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Suffix and Prefix :`}</strong>{` this is an optional form of text field. To help user indicate the type of information that should be entered in the field such as currency, unit of measurement, etc.`}</li>
      <li parentName="ol"><strong parentName="li">{`Caption :`}</strong>{` this is also optional form of text field. Provides contextual assistance like popovers, placeholder text, or helper text, in any area where the user might need additional information to provide the right input.`}</li>
    </ol>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With label" caption="Use fields of the same size in a form" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Without label" caption="Don’t vary the sizes of fields in a form" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Text field should be used when the requested input text is expected to fit within the width of the inline field. Otherwise, the input text will become hidden behind a vertical scroll which can create frustration`}</p>
    <p><strong parentName="p">{`Common places textfield appear:`}</strong>{` Forms, Filter`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> When the expected input is short.</li>
      <li> To gather basic information from users. </li>
      <li> To specify what kind of data users can enter. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> You have multiple related fields. </li>
      <li> You want longer responses from users. </li>
      <li> You want users to upload a file. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`A text field is a rectangular area in which people enter or edit small, specific pieces of text.
When the Text Field text is too long for the available horizontal space, the text truncates when text is inputted. Avoid using the Text Field if you need to let users enter longer answers that might span multiple lines.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With supporting text" caption="Use helper text to explain why an error occurred and how it's fixed" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Without supporting text" caption="Let user see error indicator without displaying helper text" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-6.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Text Fields :`}</p>
    <ul>
      <li parentName="ul">{`Validate fields when it makes sense.`}</li>
      <li parentName="ul">{`Use a number formatter to help with numeric data.`}</li>
      <li parentName="ul">{`Show a hint in a text field to help communicate its purpose.`}</li>
      <li parentName="ul">{`Use secure text fields to hide private data. Always use a secure text field when your app asks for sensitive data, such as a password.`}</li>
      <li parentName="ul">{`Use error and help messages to guide users before they enter anything and clear calm error messages when there’s a problem with what they entered.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : For short text input" caption="Use text fields for gathering small amount of text of information" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-7.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : For long text input" caption="Use text fields to gather vast amount of text of information" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-8.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With short label" caption="Input labels and placeholder text should be in sentence case" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-9.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : With long label" caption="Input label text shouldn't be truncated or wrap over two or more lines" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/field-10.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      